import { useMutation, useQuery } from '@apollo/client';
import { useAuth } from '@vyro-x/react-auth';
import { useRouter } from 'next/router';
import { createContext, ReactNode, useContext } from 'react';
import {
  AddVehiclesToWishlistMutation,
  AddVehiclesToWishlistMutationFn,
  AddVehiclesToWishlistMutationVariables,
  RemoveVehicleFromWishlistMutation,
  RemoveVehicleFromWishlistMutationFn,
  RemoveVehicleFromWishlistMutationVariables,
  VehiclesGetWishListQuery,
  VehiclesGetWishListQueryVariables,
} from '../../../../types/graphql';
import { ADD_VEHICLE_TO_WISH_LIST, REMOVE_VEHICLE_FROM_WISH_LIST } from './mutations';
import { GET_WISH_LIST } from './queries';

type WishlistContext = {
  /** An array of stocked vehicle IDs */
  items: Partial<string[]>;

  onAdd: AddVehiclesToWishlistMutationFn;
  onRemove: RemoveVehicleFromWishlistMutationFn;
  onToggle: (stockedVehicleId: string) => Promise<void>;
  isOnWishlist: (stockedVehicleId: string) => boolean;
};

const Context = createContext<WishlistContext>(null);

type Props = {
  children: ReactNode;
};

export const WishlistProvider = (props: Props) => {
  const { userId, isAuthenticated } = useAuth();
  const router = useRouter();

  const itemsResponse = useQuery<VehiclesGetWishListQuery, VehiclesGetWishListQueryVariables>(GET_WISH_LIST, {
    fetchPolicy: 'cache-and-network',
    variables: {
      userId,
    },
    skip: !userId,
  });
  const items = (itemsResponse.data?.wish_list || []).map((item) => item.stocked_vehicle_id);

  const [onAdd] = useMutation<AddVehiclesToWishlistMutation, AddVehiclesToWishlistMutationVariables>(
    ADD_VEHICLE_TO_WISH_LIST,
    {
      onCompleted: () => itemsResponse.refetch(),
    },
  );

  const [onRemove] = useMutation<RemoveVehicleFromWishlistMutation, RemoveVehicleFromWishlistMutationVariables>(
    REMOVE_VEHICLE_FROM_WISH_LIST,
    {
      onCompleted: () => itemsResponse.refetch(),
    },
  );

  const onToggle = async (stockedVehicleId: string) => {
    if (items.includes(stockedVehicleId)) {
      await onRemove({
        variables: {
          userId,
          stockedVehicleId: stockedVehicleId,
        },
      });
    } else {
      await onAdd({
        variables: {
          object: {
            user_id: userId,
            stocked_vehicle_id: stockedVehicleId,
          },
        },
      });
    }
  };

  const isOnWishlist = (stockedVehicleId: string) => items.includes(stockedVehicleId);

  const context: WishlistContext = {
    items,
    onAdd,
    onRemove,
    onToggle,
    isOnWishlist,
  };

  return <Context.Provider value={context}>{props.children}</Context.Provider>;
};

export const useWishlist = () => {
  const context = useContext<WishlistContext>(Context);

  if (!context) {
    throw new Error('useWishlist must be used within a WishlistProvider');
  }

  return context;
};
