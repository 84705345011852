import { useQuery } from '@apollo/client';
import classnames from 'classnames';
import { filter, uniqBy } from 'lodash';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { AiOutlineClose, AiOutlineCloseCircle } from 'react-icons/ai';
import { useMediaQuery } from 'react-responsive';
import {
  Filter_Locations_Enum,
  Filter_Names_Enum,
  GetFilterOptionsQuery,
  GetFilterOptionsQueryVariables,
  Stocked_Vehicles_Bool_Exp,
} from '../../../../../types/graphql';
import Button from '../../../../Button';
import CheckboxList from '../../../../CheckboxList';
import { Counter } from '../../../../Counter';
import Expandable from '../../../../Expandable';
import Modal from '../../../../Modal';
import { FinanceDrawerState } from '../CustomiseFinanceDrawer';
import { useFilters } from '../FiltersProvider';
import { Filters } from '../types';
import { FinanceField } from './fields/FinanceField';
import { MaxPriceField } from './fields/MaxPriceField';
import { MaxRepaymentField } from './fields/MaxRepaymentField';
import { SortField } from './fields/SortField';
import styles from './index.module.scss';
import { GET_FILTER_OPTIONS } from './queries';
import { id } from 'date-fns/locale';

export type Props = {
  isMobileFiltersOpen: boolean;
  setIsMobileFiltersOpen: (isOpen: boolean) => void;
  baseWhereClause: Stocked_Vehicles_Bool_Exp;
  isSearching: boolean;
  vehicleCount: number;
  onCustomiseFinance: () => void;
  financeState: FinanceDrawerState;
};

export default function FiltersSidebar(props: Props) {
  const {
    isMobileFiltersOpen,
    setIsMobileFiltersOpen,
    baseWhereClause,
    isSearching,
    vehicleCount,
    onCustomiseFinance,
    financeState,
  } = props;

  const form = useFormContext<Partial<Filters>>();
  const { register, watch, setValue } = form;

  const { resetFilters, filters, fields } = useFilters();

  const [openId, setOpenId] = useState<number>(null);
  const checkIsOpen = (id: number) => openId === id;

  const response = useQuery<GetFilterOptionsQuery, GetFilterOptionsQueryVariables>(GET_FILTER_OPTIONS, {
    variables: {
      where: baseWhereClause,
    },
  });
  const options = response.data;

  const getDefaultOptions = (field: string): { label: string; value: string }[] => {
    return uniqBy<{ label: string; value: string }>(
      (options?.[field] || [])
        .map((item) => item[field])
        .filter((item) => !!String(item).trim())
        .sort()
        .map((item) => ({
          label: item as string,
          value: JSON.stringify({ [field]: { _eq: item } } as Stocked_Vehicles_Bool_Exp),
        })),
      'label',
    );
  };

  const isPopoverFormatAllowed = useMediaQuery({ query: '(min-width: 1024px)' });

  const formattedFields = fields
    .filter((field) => field.location === Filter_Locations_Enum.LeftSidebar)
    .map((field) => {
      const fieldProps = {
        field,
        financeState,
        onCustomiseFinance,
        onClose: () => setOpenId(null),
      };

      switch (field.name) {
        case Filter_Names_Enum.Sort:
          return {
            name: field.name,
            heading: field.label,
            format: 'expandable',
            hideIndicator: true,
            panel: <SortField {...fieldProps} />,
          };

        case Filter_Names_Enum.Make:
        case Filter_Names_Enum.Model:
        case Filter_Names_Enum.Badge:
        case Filter_Names_Enum.Colour:
        case Filter_Names_Enum.ProductionYear:
        case Filter_Names_Enum.FuelType:
        case Filter_Names_Enum.Interior:
        case Filter_Names_Enum.Packs:
        case Filter_Names_Enum.PackCodes:
          return {
            name: field.name,
            heading: field.label,
            format: 'expandable',
            panel: (
              <CheckboxList
                formFieldProps={{ className: 'hug' }}
                name={field.name}
                options={field.options || getDefaultOptions(field.name)}
                register={register}
                setValue={setValue}
                watch={watch}
                size={'small'}
              ></CheckboxList>
            ),
          };

        case Filter_Names_Enum.MaxPrice:
          return {
            name: field.name,
            heading: field.label,
            format: 'expandable',
            panel: <MaxPriceField {...fieldProps} />,
          };

        case Filter_Names_Enum.MaxRepayment:
          return {
            name: field.name,
            heading: field.label,
            format: 'popover',
            hideIndicator: filters[Filter_Names_Enum.MaxRepayment] === 5_000_00,
            panel: <MaxRepaymentField {...fieldProps} />,
          };

        default:
          return null;
      }
    })
    .filter(Boolean)
    .map((item, id) => ({
      id,
      ...item,
    }));

  return (
    <>
      <div className={classnames(styles.sidebar, isMobileFiltersOpen && styles.isMobileFiltersOpen)}>
        <div className={styles.actions}>
          <div className={styles.count}>
            Search results: <Counter isLoading={vehicleCount === 0 && isSearching} value={vehicleCount} />
          </div>

          <Button className={styles.close} isText size="small" onClick={() => setIsMobileFiltersOpen(false)}>
            <AiOutlineClose className="inlineIcon" />
            Close
          </Button>
        </div>

        <div className={styles.items}>
          {formattedFields.map((item, index) => {
            const isOpen = checkIsOpen(item.id);
            const value = watch(item.name as keyof Filters);
            const isActive = value && Array.isArray(value) ? value.length > 0 : !!value;

            // avoid caching
            const key = item.heading + Math.random();

            const heading = (
              <button
                type="button"
                key={key}
                onClick={() => setOpenId(isOpen ? null : item.id)}
                className={classnames(
                  styles.item,
                  item.format === 'popover' && styles.hasPopover,
                  isOpen && styles.isOpen,
                  isActive && styles.isActive,
                )}
              >
                <div className={styles.label}>{item.heading}</div>

                {!item.hideIndicator && (
                  <div className={styles.indicator}>
                    <div />
                  </div>
                )}

                <div className={classnames(styles.icon, styles[item.format])} aria-hidden="true">
                  <span />
                  <span />
                  <span />
                </div>
              </button>
            );

            return (
              <div key={index}>
                {heading}
                {(item.format !== 'popover' || !isPopoverFormatAllowed) && (
                  <Expandable isOpen={isOpen}>
                    <div className={styles.panel}>{item.panel}</div>
                  </Expandable>
                )}
              </div>
            );
          })}
        </div>

        <Button isText color="grey" className={styles.clearFilter} size="small" onClick={() => resetFilters()}>
          <AiOutlineCloseCircle className="inlineIcon" />
          Clear filters
        </Button>
      </div>

      {formattedFields
        .filter((item) => item.format === 'popover')
        .map((item, index) => (
          <Modal
            key={index}
            isOpen={checkIsOpen(item.id)}
            onClose={() => setOpenId(null)}
            title={item.heading}
            className={styles.modal}
          >
            <div className={classnames(styles.panel, styles.popoverPanel)}>{item.panel}</div>
          </Modal>
        ))}
    </>
  );
}
