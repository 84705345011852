import { Stocked_Vehicles_Bool_Exp } from '../../../../../types/graphql';
import { SettlementMethod } from '../../../../payment-calculators/types';
import { FinanceDrawerState } from '../CustomiseFinanceDrawer';
import { Filters } from '../types';
import { coherceArray } from './coherceArray';
import { reverseLookupMaxRepayment } from './reverseLookupMaxRepayment';

type Args = {
  userId?: string;
  filters: Filters;
  financeState?: FinanceDrawerState;
};

export const getFiltersClause = ({ filters, userId, financeState }: Args): Stocked_Vehicles_Bool_Exp => {
  let filtersClause: Stocked_Vehicles_Bool_Exp['_and'] = [];

  const textFilters: Array<keyof Filters> = [
    'production_year',
    'make',
    'model',
    'badge',
    'condition',
    'body_type',
    'colour',
    'interior',
    'max_price',
    'pack_codes',
    'fuel_type',
    'packs',
  ];
  textFilters.forEach((key) => {
    if ((filters[key] as string[])?.length) {
      filtersClause = [
        ...filtersClause,
        {
          _or: coherceArray(filters[key]).map((value) => JSON.parse(value) as Stocked_Vehicles_Bool_Exp),
        },
      ];
    }
  });

  if (filters.is_on_wishlist && userId) {
    filtersClause = [
      ...filtersClause,
      {
        wish_lists: { user_id: { _eq: userId } },
      },
    ];
  }

  if (filters.has_discount) {
    filtersClause = [
      ...filtersClause,
      {
        has_discount: { _eq: true },
      },
    ];
  }

  if (filters.max_repayment && !!financeState?.args[financeState.settlement_method]) {
    const maxPrice = reverseLookupMaxRepayment(filters.max_repayment, financeState);
    filtersClause = [
      ...filtersClause,
      {
        max_price: { _lte: maxPrice },
      },
    ];
  }

  return {
    _and: filtersClause,
  };
};
