import classnames from 'classnames';
import React, { useEffect } from 'react';
import { AiOutlineCheck } from 'react-icons/ai';
import FormField, { Props as FormFieldProps } from '../FormField';
import styles from './index.module.scss';

export type Props = {
  children?: React.ReactNode;
  className?: string;
  defaultValue?: Array<string>;
  errors?: any;
  helpText?: React.ReactNode;
  description?: React.ReactNode;
  label?: React.ReactNode;
  name?: string;
  options: Array<{
    label: React.ReactNode;
    value: string;
    isHidden?: boolean;
    isDisabled?: boolean;
    price?: string;
    description?: React.ReactNode;
    dividerAbove?: React.ReactNode;
    dividerBelow?: React.ReactNode;
    image?: string;
  }>;
  register?: any;
  rules?: any;
  watch: any;
  setValue: any;
  formFieldProps?: Partial<FormFieldProps>;
  scroll?: boolean;
  layout?: 'list' | 'compact' | 'image';
  size?: 'small' | 'normal';
  forceArrayValues?: boolean;
} & Record<string, any>;

export default function CheckboxList(props: Props) {
  const {
    children,
    className,
    defaultValue,
    errors,
    helpText,
    description,
    label,
    name,
    options = [],
    register,
    rules,
    formFieldProps = {},
    watch,
    scroll = true,
    size = 'normal',
    layout = 'compact',
    setValue,
    forceArrayValues,
    ...otherProps
  } = props;

  const hasErrors = errors && !!errors[name];

  useEffect(() => {
    if (typeof defaultValue !== 'undefined') {
      setValue(name, defaultValue);
    }
  }, []);

  const selectedValues = watch(name);

  const isSelected = (value: string): boolean => {
    if (Array.isArray(selectedValues)) {
      return selectedValues.includes(value);
    }

    return selectedValues === value;
  };

  return (
    <FormField
      label={label}
      description={description}
      name={name}
      helpText={helpText}
      errors={errors}
      {...formFieldProps}
    >
      <div
        className={classnames(
          styles.checkboxList,
          layout === 'compact' ? styles.compactLayout : styles.listLayout,
          layout === 'image' && styles.imageLayout,
          scroll && styles.scroll,
          styles[size],
          hasErrors && styles.hasErrors,
          otherProps.options_layout && styles[otherProps.options_layout],
        )}
      >
        {/* 
          this checkbox is added because the react-hook-form doesn't expect values of checkboxes with one option as array.
          So we ensure that each item have at least 2 items and the value is considered to be an array
        */}
        {forceArrayValues && options.length < 2 && (
          <input style={{ display: 'none' }} type="checkbox" value={''} {...register(name, rules)} />
        )}

        {options.map((op, i) => (
          <>
            {op.dividerAbove && (
              <div key={`${i}-divider-above`} className={styles.divider}>
                {op.dividerAbove}
              </div>
            )}
            <label
              key={`${i}-label` + Math.random() /* avoid caching */}
              onClick={(e) => {
                if (op.isDisabled) {
                  e.preventDefault();
                }
              }}
              className={classnames(
                styles.option,
                op.isHidden && styles.isHidden,
                op.isDisabled && styles.isDisabled,
                isSelected(op.value) && styles.isSelected,
              )}
              data-test={op.value}
            >
              <input
                className={styles.input}
                type="checkbox"
                value={op.value}
                {...otherProps}
                {...register(name, rules)}
              />

              <div className={styles.content}>
                {layout === 'image' ? (
                  <div className={styles.image} style={{ backgroundImage: `url(${op.image})` }} />
                ) : (
                  <div className={styles.check} aria-hidden="true">
                    <AiOutlineCheck />
                  </div>
                )}

                <div className={styles.body}>
                  <div className={styles.label}>{op.label}</div>
                  <div className={styles.description}>{op.description}</div>
                </div>

                {op.price && <div className={styles.price}>{op.price}</div>}
              </div>
            </label>
            {op.dividerBelow && (
              <div key={`${i}-divider-below`} className={styles.divider}>
                {op.dividerBelow}
              </div>
            )}
          </>
        ))}
      </div>
    </FormField>
  );
}
