import { Filters } from '../types';
import { FilterDataType, filterSchema } from './filterSchema';

export const getFiltersFromUrl = (): Partial<Filters> => {
  let filters: Partial<Filters> = {};

  if (typeof window === 'undefined') {
    return filters;
  }

  const { searchParams } = new URL(window.location.href);

  const keys = Array.from(searchParams.keys());

  keys.forEach((key) => {
    const dataType = filterSchema[key]?.type;
    const value = searchParams.getAll(key);

    if (!dataType) {
      return;
    }

    let cohercedValue: any;

    if (dataType === FilterDataType.String) {
      cohercedValue = value[0];
      if (typeof cohercedValue !== 'string') {
        return;
      }
    }

    if (dataType === FilterDataType.StringArray) {
      cohercedValue = value;
      if (cohercedValue.some((v: string) => typeof v !== 'string')) {
        return;
      }
    }

    if (dataType === FilterDataType.Number) {
      cohercedValue = Number(value[0]);
      if (isNaN(cohercedValue)) {
        return;
      }
    }

    if (dataType === FilterDataType.NumberArray) {
      cohercedValue = value.map(Number);
      if (cohercedValue.some((v: string) => isNaN(Number(v)))) {
        return;
      }
    }

    if (dataType === FilterDataType.Boolean) {
      cohercedValue = value[0]?.toLowerCase() === 'true';

      if (!cohercedValue) {
        return;
      }
    }

    filters = { ...filters, [key]: cohercedValue };
  });

  return filters;
};
