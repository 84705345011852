import { useFormContext } from 'react-hook-form';
import { useCurrency } from '../../../../../../../services/formatCurrency';
import RadioList from '../../../../../../RadioList';
import { FieldProps } from '../types';

export const MaxPriceField = ({ field }: FieldProps) => {
  const { d } = useCurrency();

  const { register, setValue, watch } = useFormContext();

  const options =
    field.options ||
    [10_000_00, 25_000_00, 50_000_00, 75_000_00, 100_000_00, 150_000_00, 200_000_00].map((value) => ({
      label: d(value),
      value: value.toString(),
    }));

  return (
    <div key={`max-price` + Math.random() /* avoid caching */}>
      Up to:
      <RadioList
        formFieldProps={{ className: 'hug' }}
        name={field.name}
        options={options}
        register={register}
        setValue={setValue}
        watch={watch}
        size={'small'}
      ></RadioList>
    </div>
  );
};
