import { useEffect } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useShowroom } from '../../../../../services/showrooms/useShowroom';
import Drawer from '../../../../Drawer';
import {
  FixedRateFinanceArgs,
  FixedRateFinanceShowroomSettings,
  getFixedRateDefaults,
} from '../../../../payment-calculators/products/FixedRate/calculate';
import { FixedRateCalculatorControls } from '../../../../payment-calculators/products/FixedRate/Controls';
import FixedRateCalculatorControlsDescription from '../../../../payment-calculators/products/FixedRate/PlanDescription';
import FixedRateCalculatorSummaryTable from '../../../../payment-calculators/products/FixedRate/SummaryTable';
import {
  getGuaranteedFutureValueDefaults,
  GuaranteedFutureValueFinanceArgs,
} from '../../../../payment-calculators/products/GuaranteedFutureValue/calculate';
import { GuaranteedFutureValueCalculatorControls } from '../../../../payment-calculators/products/GuaranteedFutureValue/Controls';
import GuaranteedFutureValueCalculatorDescription from '../../../../payment-calculators/products/GuaranteedFutureValue/Description';
import GuaranteedFutureValueCalculatorSummaryTable from '../../../../payment-calculators/products/GuaranteedFutureValue/SummaryTable';
import SubscriptionCalculatorDescription from '../../../../payment-calculators/products/Subscription/Description';
import FinanceDisclaimer from '../../../../payment-calculators/shared/FinanceDisclaimer';
import { useSettlementMethodName } from '../../../../payment-calculators/shared/useSettlementMethodName';
import { SettlementMethod } from '../../../../payment-calculators/types';
import Select from '../../../../Select';

export type FinanceDrawerState = {
  settlement_method: SettlementMethod;
  args: {
    [SettlementMethod.Finance_FixedRate]: FixedRateFinanceArgs;
    [SettlementMethod.Finance_GuaranteedFutureValue]: GuaranteedFutureValueFinanceArgs;
  };
};

type Props = {
  isOpen: boolean;
  onClose: () => any;
  defaultSettlementMethod: SettlementMethod;
  onChange: (state: FinanceDrawerState) => any;
  financeSettings: FixedRateFinanceShowroomSettings;
};

export const CustomiseFinanceDrawer = (props: Props) => {
  const { isOpen, onClose, defaultSettlementMethod = SettlementMethod.Cash, onChange, financeSettings } = props;

  const showroom = useShowroom();

  const defaultArgs = {
    [SettlementMethod.Finance_FixedRate]: getFixedRateDefaults(financeSettings),
    [SettlementMethod.Finance_GuaranteedFutureValue]: getGuaranteedFutureValueDefaults(financeSettings),
  };

  const getDefaultValues = () => ({
    settlement_method: defaultSettlementMethod,
    args: defaultArgs,
  });

  const form = useForm<FinanceDrawerState>({
    defaultValues: getDefaultValues(),
  });

  const { register, control, setValue, watch } = form;

  const state = useWatch({ control });
  useEffect(() => {
    onChange(state as FinanceDrawerState);
  }, [state]);

  const settlementOptions = [
    { label: useSettlementMethodName(SettlementMethod.Finance_FixedRate), value: SettlementMethod.Finance_FixedRate },
    ['polestar-nz', 'cupra-nz'].includes(showroom.code) && {
      label: useSettlementMethodName(SettlementMethod.Finance_GuaranteedFutureValue),
      value: SettlementMethod.Finance_GuaranteedFutureValue,
    },
  ].filter(Boolean);

  return (
    <Drawer position="left" showOverlay={false} size="small" isOpen={isOpen} onClose={onClose}>
      <Drawer.Title>
        <h2>Customise repayments</h2>
      </Drawer.Title>

      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(() => {})}>
          <div style={{ display: settlementOptions.length > 1 ? 'block' : 'none' }}>
            <Select
              label="Payment method"
              name={'settlement_method'}
              options={settlementOptions}
              allowUnselect={false}
              register={register}
              control={control}
              setValue={setValue}
              watch={watch}
            />
          </div>

          {(() => {
            switch (watch('settlement_method')) {
              case SettlementMethod.Finance_FixedRate:
                return (
                  <>
                    <FixedRateCalculatorControls
                      name={`args.${SettlementMethod.Finance_FixedRate}`}
                      maxCreditAmount={400_000_00}
                    />
                    <FixedRateCalculatorSummaryTable
                      args={watch(`args.${SettlementMethod.Finance_FixedRate}`) as FixedRateFinanceArgs}
                    />
                  </>
                );
              case SettlementMethod.Finance_GuaranteedFutureValue:
                return (
                  <>
                    <GuaranteedFutureValueCalculatorControls
                      name={`args.${SettlementMethod.Finance_GuaranteedFutureValue}`}
                      maxCreditAmount={400_000_00}
                    />
                    <GuaranteedFutureValueCalculatorSummaryTable
                      args={
                        watch(
                          `args.${SettlementMethod.Finance_GuaranteedFutureValue}`,
                        ) as GuaranteedFutureValueFinanceArgs
                      }
                    />
                  </>
                );
              default:
                return null;
            }
          })()}

          <hr />

          <h4>About</h4>
          <p className="smallGap">
            {(() => {
              switch (watch('settlement_method')) {
                case SettlementMethod.Finance_FixedRate:
                  return <FixedRateCalculatorControlsDescription />;
                case SettlementMethod.Finance_GuaranteedFutureValue:
                  return <GuaranteedFutureValueCalculatorDescription />;
                case SettlementMethod.Subscribe:
                  return <SubscriptionCalculatorDescription />;
                case SettlementMethod.Cash:
                  return 'Pay the full amount for your car upfront.';
                default:
                  return null;
              }
            })()}
          </p>

          <h4>Disclaimer</h4>
          <p className="legal smallGap">
            <FinanceDisclaimer />
          </p>
        </form>
      </FormProvider>
    </Drawer>
  );
};
