import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';
import { AiOutlineLoading, AiOutlineSearch } from 'react-icons/ai';
import Input from '../../../../Input';
import styles from './index.module.scss';

type Props = {
  isSearching: boolean;
};

export const SearchField = (props: Props) => {
  const { isSearching } = props;

  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div className={styles.searchField}>
      <Input
        name="search"
        type="search"
        register={register}
        errors={errors}
        prefixIcon={<AiOutlineSearch />}
        placeholder="Search..."
        autoComplete="off"
        autoCorrect="off"
        isRounded
        isBorderThin
        suffixIcon={
          <div className={classNames(styles.loadingIcon, isSearching && styles.isSearching)}>
            <AiOutlineLoading />
          </div>
        }
        formFieldProps={{ className: 'hug' }}
      />
    </div>
  );
};
