import { useQuery } from '@apollo/client';
import { useAuth } from '@vyro-x/react-auth';
import { PricingConfig } from '@vyro-x/vehicle-order-types';
import classnames from 'classnames';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import { usePricingCodes } from '../../services/pricingCodes/usePricingCodes';
import { useShowroom } from '../../services/showrooms/useShowroom';
import { useShowroomSettings } from '../../services/showrooms/useShowroomSettings';
import { useDebounce } from '../../services/useDebounce';
import { usePageMeta } from '../../services/usePageMeta';
import {
  Stocked_Vehicles_Bool_Exp,
  VehiclesGetStockedVehiclesQuery,
  VehiclesGetStockedVehiclesQueryVariables,
} from '../../types/graphql';
import AuthDrawer from '../AuthDrawer';
import Container from '../Container';
import { Counter } from '../Counter';
import CurrentPromoBanner from '../CurrentPromoBanner';
import Footer from '../Footer';
import Header from '../Header';
import { FixedRateFinanceShowroomSettings } from '../payment-calculators/products/FixedRate/calculate';
import { GuaranteedFutureValueFinanceShowroomSettings } from '../payment-calculators/products/GuaranteedFutureValue/calculate';
import FinanceDisclaimer from '../payment-calculators/shared/FinanceDisclaimer';
import {
  getSettlementMethodName,
  useSettlementMethodName,
} from '../payment-calculators/shared/useSettlementMethodName';
import { SettlementMethod } from '../payment-calculators/types';
import { Skeleton } from '../Skeleton';
import VehicleCard from '../VehicleCard';
import { DropInAd, DropInAdType } from './components/DropInAd';
import EnquireNowBanner from './components/EnquireNowBanner';
import { CustomiseFinanceDrawer, FinanceDrawerState } from './components/Filters/CustomiseFinanceDrawer';
import { defaultFilterFields, FilterField } from './components/Filters/defaultFilterFields';
import { FilterBar } from './components/Filters/FilterBar';
import { FiltersProvider, useFilters } from './components/Filters/FiltersProvider';
import FiltersSidebar from './components/Filters/FiltersSidebar';
import { Filters } from './components/Filters/types';
import { getFiltersClause } from './components/Filters/utils/getFiltersClause';
import { getSortClause } from './components/Filters/utils/getSortClause';
import { useUrlWithPrefill } from './components/Filters/utils/useUrlWithPrefill';
import { VehicleListingPageHero } from './components/Hero';
import NothingFound from './components/NothingFound';
import { useWishlist, WishlistProvider } from './components/Wishlist/WishlistProvider';
import styles from './index.module.scss';
import { SEARCH_STOCKED_VEHICLES } from './queries';
import { CalculatedFinance, calculateFinance } from './utils/calculateFinance';

type Vehicle = VehiclesGetStockedVehiclesQuery['search_stocked_vehicles'][0];

type ListItem =
  | {
      type: 'vehicle';
      data: Vehicle;
    }
  | {
      type: 'drop_in_ad';
      data: DropInAdType;
    };

export type VehicleListingPageProps = {
  title: string;
  description?: string;
  headerImageUrl?: string;
  headerTextColor?: string;
  headerSize?: 'small' | 'medium' | 'large';
  metaTitle?: string;
  metaDescription?: string;
  whereClause: Stocked_Vehicles_Bool_Exp;
  dropInAds?: Array<DropInAdType>;
  filterFields?: FilterField[];
  defaultSettlementMethod?: SettlementMethod;
  isCustomiseFinanceOpen: boolean;
  setIsCustomiseFinanceOpen: (open: boolean) => void;
  financeState: FinanceDrawerState;
  financeSettings: FixedRateFinanceShowroomSettings & GuaranteedFutureValueFinanceShowroomSettings;
};

function VehicleListingPageWithContext(props: VehicleListingPageProps) {
  const {
    title,
    description,
    headerImageUrl,
    headerTextColor,
    headerSize,
    metaTitle,
    metaDescription,
    dropInAds = [],
    whereClause,
    isCustomiseFinanceOpen,
    setIsCustomiseFinanceOpen,
    financeState,
  } = props;

  const pageMeta = usePageMeta({
    title: metaTitle || title,
    description: metaDescription || description,
  });

  const router = useRouter();
  const showroom = useShowroom();
  const showroomSettings = useShowroomSettings([
    'feature_wishlist_enabled',
    'feature_vehicle_card_variant',
    'feature_enquire_now_banner_enabled',
  ]);
  const { isAuthenticated, userId } = useAuth();
  const { pricingCode } = usePricingCodes();

  const wishlist = useWishlist();
  const { filters, resetFilters } = useFilters();

  const [hasInit, setHasInit] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setHasInit(true);
    }, 1000);
  }, []);

  const [isMobileFiltersOpen, setIsMobileFiltersOpen] = useState(false);

  const [debouncedFilters] = useDebounce(filters, 300);

  const urlWithPrefill = useUrlWithPrefill(debouncedFilters);

  const settlementMethodName = useSettlementMethodName(financeState?.settlement_method);

  const filtersClause = getFiltersClause({
    userId,
    filters: debouncedFilters as Filters,
    financeState,
  });
  const sortClause = getSortClause({
    filters: debouncedFilters as Filters,
  });

  const baseWhereClause: Stocked_Vehicles_Bool_Exp = {
    _and: [
      {
        showroom_stocked_vehicles: {
          showroom_id: { _eq: showroom.id },
        },
      },
      whereClause,
    ],
  };

  const searchVehiclesResponse = useQuery<VehiclesGetStockedVehiclesQuery, VehiclesGetStockedVehiclesQueryVariables>(
    SEARCH_STOCKED_VEHICLES,
    {
      variables: {
        search: debouncedFilters.search || '',
        where: {
          _and: [...baseWhereClause._and, filtersClause],
        },
        pricingCode: pricingCode?.code,
        sort: sortClause,
      },
      skip: !pricingCode,
      fetchPolicy: 'no-cache',
    },
  );
  const allVehicles = searchVehiclesResponse.data?.search_stocked_vehicles || [];
  const isSearching = searchVehiclesResponse.loading;

  const vehicles = allVehicles.filter((vehicle) => {
    const pricing: PricingConfig = vehicle.pricing_config;

    return pricing?.version === 'v2' && pricing?.driveAwayPrice;
  });

  const finance = allVehicles.reduce((acc, vehicle) => {
    const methods = [
      {
        settlement_method: SettlementMethod.Finance_FixedRate,
        calculation: calculateFinance(
          vehicle?.pricing_config?.driveAwayPrice,
          { ...financeState, settlement_method: SettlementMethod.Finance_FixedRate },
          vehicle,
        ),
      },
      {
        settlement_method: SettlementMethod.Finance_GuaranteedFutureValue,
        calculation: calculateFinance(
          vehicle?.pricing_config?.driveAwayPrice,
          { ...financeState, settlement_method: SettlementMethod.Finance_GuaranteedFutureValue },
          vehicle,
        ),
      },
    ].filter((method) => !!method.calculation?.repayment);

    const preferred = methods.find((item) => item.settlement_method === financeState?.settlement_method) || methods[0];
    const name = getSettlementMethodName(showroom.code, preferred?.settlement_method);

    return {
      ...acc,
      [vehicle.id]: {
        ...(preferred?.calculation || {}),
        name,
        methods,
      },
    };
  }, {} as any);

  const listItems: ListItem[] = vehicles.reduce((acc, vehicle, i) => {
    const is5thCar = (i + 1) % 5 === 0;
    const dropInAd = dropInAds[Math.floor(i / 5) % dropInAds.length];

    const vehicleListItem: ListItem = { type: 'vehicle', data: vehicle };

    if (is5thCar && dropInAd) {
      return [...acc, vehicleListItem, { type: 'drop_in_ad', data: dropInAd }];
    }

    return [...acc, vehicleListItem];
  }, [] as ListItem[]);

  const [vehicleIdToAddToWishlistAfterAuth, setVehicleIdToAddToWishlistAfterAuth] = useState<string>(null);

  const onAddToWishlist = async (vehicleId: string) => {
    if (!isAuthenticated) {
      setVehicleIdToAddToWishlistAfterAuth(vehicleId);
      setIsAuthDrawerOpen(true);
    } else {
      await wishlist.onToggle(vehicleId);
    }
  };

  const [isAuthDrawerOpen, setIsAuthDrawerOpen] = useState(false);
  const postAuthCallbackUrl = useMemo(() => {
    if (!urlWithPrefill) {
      return;
    }

    const url = new URL(urlWithPrefill);

    if (vehicleIdToAddToWishlistAfterAuth) {
      url.searchParams.append('vehicleIdToAddToWishlistAfterAuth', vehicleIdToAddToWishlistAfterAuth);
    }

    return url.toString();
  }, [urlWithPrefill, vehicleIdToAddToWishlistAfterAuth]);

  /**
   * When a user adds a car to the wishlist, if they are not logged in they
   * are redirected back to this page post-signup with the URL query param
   * `vehicleIdToAddToWishlistAfterAuth` present
   *
   * This effect adds that vehicle to the wishlist, then removes the URL param
   */
  useEffect(() => {
    if (!router.isReady || !isAuthenticated) {
      return;
    }

    const vehicleId = router.query.vehicleIdToAddToWishlistAfterAuth;

    if (typeof vehicleId !== 'string' || !vehicleId) {
      return;
    }

    (async () => {
      try {
        await wishlist.onAdd({
          variables: {
            object: {
              user_id: userId,
              stocked_vehicle_id: vehicleId,
            },
          },
        });

        router.replace({
          query: {
            ...router.query,
            vehicleIdToAddToWishlistAfterAuth: '',
          },
        });
      } catch (err) {
        console.error('Failed to automatically add a vehicle to the wishlist after auth');
        console.error(err);
      }
    })();
  }, [router.isReady, isAuthenticated]);

  return (
    <>
      <Head>{pageMeta}</Head>

      <Header />

      <main>
        <CurrentPromoBanner />

        <VehicleListingPageHero
          title={title}
          description={description}
          headerImageUrl={headerImageUrl}
          headerTextColor={headerTextColor}
          size={headerSize}
        />

        <section id="list">
          <FilterBar
            isSearching={isSearching}
            isMobileFiltersOpen={isMobileFiltersOpen}
            setIsMobileFiltersOpen={setIsMobileFiltersOpen}
            isCustomiseFinanceOpen={isCustomiseFinanceOpen}
            setIsCustomiseFinanceOpen={setIsCustomiseFinanceOpen}
          />

          <Container className={classnames(styles.results)}>
            <div className={styles.sidebar}>
              <FiltersSidebar
                isMobileFiltersOpen={isMobileFiltersOpen}
                setIsMobileFiltersOpen={setIsMobileFiltersOpen}
                baseWhereClause={baseWhereClause}
                isSearching={isSearching}
                vehicleCount={vehicles.length}
                onCustomiseFinance={() => setIsCustomiseFinanceOpen(true)}
                financeState={financeState}
              />
            </div>

            <div className={styles.grid}>
              <div className={styles.count} data-test={'search-results'}>
                Search results: <Counter isLoading={vehicles.length === 0 && isSearching} value={vehicles.length} />
              </div>

              {(isSearching || vehicles.length > 0) && (
                <div className={styles.items}>
                  {isSearching && vehicles.length === 0
                    ? // Skeleton loaders
                      new Array(6).fill('').map((_, i) => <Skeleton key={i} height={'450px'} />)
                    : // Vehicle cards
                      listItems.map((listItem, i) => {
                        if (listItem.type === 'drop_in_ad') {
                          const dropInAd = listItem.data;
                          return <DropInAd {...dropInAd} key={i} />;
                        }

                        const vehicle = listItem.data;

                        return (
                          <VehicleCard
                            key={vehicle.id}
                            vehicle={vehicle}
                            className={styles.item}
                            href={`/vehicles/stocked/${vehicle.code}?backUrl=${encodeURIComponent(urlWithPrefill)}`}
                            variant={showroomSettings.feature_vehicle_card_variant}
                            finance={finance[vehicle.id] || null}
                            isOnWishlist={wishlist.isOnWishlist(vehicle.id)}
                            onAddToWishlist={
                              showroomSettings.feature_wishlist_enabled
                                ? () => {
                                    onAddToWishlist(vehicle.id);
                                  }
                                : null
                            }
                          />
                        );
                      })}
                </div>
              )}

              {/* No results */}
              {vehicles.length === 0 && !isSearching && hasInit && <NothingFound onReset={resetFilters} />}
            </div>
          </Container>
        </section>

        <section className="largeGap">
          <Container>
            <p className="legal ">
              <strong>* Important finance information</strong>
              <FinanceDisclaimer />
            </p>
          </Container>
        </section>
      </main>

      {showroomSettings.feature_enquire_now_banner_enabled && <EnquireNowBanner />}

      <Footer />

      <AuthDrawer
        isOpen={isAuthDrawerOpen}
        onClose={() => setIsAuthDrawerOpen(false)}
        authArgs={{
          post_auth_callback_url: postAuthCallbackUrl,
        }}
        onAuth={async (user) => {
          if (vehicleIdToAddToWishlistAfterAuth) {
            await wishlist.onAdd({
              variables: {
                object: {
                  user_id: user.id,
                  stocked_vehicle_id: vehicleIdToAddToWishlistAfterAuth,
                },
              },
            });
          }
        }}
      />
    </>
  );
}

export default function VehicleListingPage(
  props: Omit<VehicleListingPageProps, 'isCustomiseFinanceOpen' | 'setIsCustomiseFinanceOpen' | 'financeState'>,
) {
  const filterFields = props.filterFields?.length >= 1 ? props.filterFields : defaultFilterFields;

  const [isCustomiseFinanceOpen, setIsCustomiseFinanceOpen] = useState(false);

  const [financeState, setFinanceState] = useState<FinanceDrawerState>(null);

  const router = useRouter();

  return (
    <>
      <FiltersProvider fields={filterFields} name={`listing_page_${router.asPath.split('?')[0]}`}>
        <WishlistProvider>
          <VehicleListingPageWithContext
            {...props}
            isCustomiseFinanceOpen={isCustomiseFinanceOpen}
            setIsCustomiseFinanceOpen={setIsCustomiseFinanceOpen}
            financeState={financeState}
          />
        </WishlistProvider>
      </FiltersProvider>
      <CustomiseFinanceDrawer
        isOpen={isCustomiseFinanceOpen}
        onClose={() => setIsCustomiseFinanceOpen(false)}
        defaultSettlementMethod={props.defaultSettlementMethod}
        onChange={setFinanceState}
        financeSettings={props.financeSettings}
      />
    </>
  );
}
