import React, { useEffect, useState, useRef } from 'react';

type Props = {
  value: number;
  isLoading?: boolean;
};

/**
 * Shows an animated counting number whenever the value changes
 */
export const Counter = (props: Props) => {
  const [currentValue, setCurrentValue] = useState(props.value);
  const [countDirection, setCountDirection] = useState<'up' | 'down'>('up');
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (currentValue !== props.value) {
      intervalRef.current = setInterval(() => {
        setCurrentValue((prevValue) => {
          let alter: number;
          const diff = Math.abs(props.value - prevValue);
          if (diff > 100) {
            alter = 50;
          } else if (diff > 10) {
            alter = 5;
          } else {
            alter = 1;
          }

          if (countDirection === 'down') {
            alter = 0 - alter;
          }

          return prevValue + alter;
        });
      }, 16);

      return () => {
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
      };
    }
  }, [props.value, countDirection, currentValue]);

  useEffect(() => {
    if (currentValue === props.value && intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  }, [currentValue, props.value]);

  useEffect(() => {
    if (props.value > currentValue) {
      setCountDirection('up');
    }
    if (props.value < currentValue) {
      setCountDirection('down');
    }
  }, [props.value]);

  if (props.isLoading) {
    return <>...</>;
  }

  return <>{currentValue}</>;
};
