import { Filters } from '../types';

export const useUrlWithPrefill = (filters: Partial<Filters>) => {
  if (typeof window === 'undefined') {
    return null;
  }

  const url = new URL(window.location.href);

  Object.entries(filters).forEach(([key, value]) => {
    url.searchParams.delete(key);

    if (typeof value === 'string' || typeof value === 'number') {
      url.searchParams.set(key, String(value));
    }

    if (typeof filters[key] === 'boolean' && value === true) {
      url.searchParams.set(key, 'true');
    }

    if (Array.isArray(value) && (value as string[]).every((v) => typeof v === 'string' || typeof v === 'number')) {
      value.forEach((v) => url.searchParams.append(key, String(v)));
    }
  });

  url.searchParams.delete('cleared');

  return url.toString();
};
