import { useEffect, useState } from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { useShowroomSettings } from '../../../../services/showrooms/useShowroomSettings';
import Button from '../../../Button';
import styles from './index.module.scss';
import classnames from 'classnames';

type Props = {
  onReset: () => any;
};

export default function NothingFound(props: Props) {
  const { onReset } = props;

  const showroomSetting = useShowroomSettings(['square_logo']);

  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 800);
  }, []);

  return (
    <div className={classnames(styles.nothingFound, isVisible && styles.isVisible)}>
      <div className={styles.logo}>
        <img src={showroomSetting.square_logo} alt="logo" />
      </div>
      <h4>No results found...</h4>
      <p className="smallGap">Try broadening your search criteria.</p>

      <Button size="small" color="grey" isRounded onClick={onReset}>
        <AiOutlineCloseCircle className="inlineIcon" />
        Clear filters
      </Button>
    </div>
  );
}
