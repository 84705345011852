import { gql } from '@apollo/client';

export const ADD_VEHICLE_TO_WISH_LIST = gql`
  mutation AddVehiclesToWishlist($object: wish_list_insert_input!) {
    insert_wish_list_one(object: $object) {
      id
    }
  }
`;

export const REMOVE_VEHICLE_FROM_WISH_LIST = gql`
  mutation RemoveVehicleFromWishlist($userId: uuid!, $stockedVehicleId: uuid!) {
    delete_wish_list(where: { stocked_vehicle_id: { _eq: $stockedVehicleId }, user_id: { _eq: $userId } }) {
      affected_rows
    }
  }
`;
