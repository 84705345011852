import { ImageProps } from 'next/image';
import React from 'react';
import { useShowroomSettings } from '../../services/showrooms/useShowroomSettings';
import StandardBanner from './variants/Standard';
import CbaBanner from './variants/Cba';

export type Props = {
  className?: string;
  children?: React.ReactNode;
  aside?: React.ReactNode;
  textColor?: string;
  background?: any;
  backgroundOpacity?: number;
  transparentBg?: boolean;
  color?: 'white' | 'black';
  backgroundLoader?: ImageProps['loader'];
  size?: 'small' | 'medium' | 'large';
} & Record<string, any>;

export default function Banner(props: Props) {
  const showroomSettings = useShowroomSettings(['banner_variant']);

  switch (showroomSettings.banner_variant) {
    case 'cba':
      return <CbaBanner {...props} />;
    default:
      return <StandardBanner {...props} />;
  }
}
