import { AiOutlineFilter, AiOutlineHeart, AiOutlineStar } from 'react-icons/ai';
import { FaCalculator } from 'react-icons/fa';
import { usePricingCodes } from '../../../../../services/pricingCodes/usePricingCodes';
import { Filter_Locations_Enum, Filter_Names_Enum } from '../../../../../types/graphql';
import Button from '../../../../Button';
import Container from '../../../../Container';
import Dropdown from '../../../../Dropdown';
import { useFilters } from '../FiltersProvider';
import { SearchField } from '../SearchField';
import styles from './index.module.scss';

type Props = {
  isSearching: boolean;
  isMobileFiltersOpen: boolean;
  setIsMobileFiltersOpen: (isOpen: boolean) => void;
  isCustomiseFinanceOpen: boolean;
  setIsCustomiseFinanceOpen: (isOpen: boolean) => void;
};

export const FilterBar = (props: Props) => {
  const {
    isSearching,
    isMobileFiltersOpen,
    setIsMobileFiltersOpen,
    isCustomiseFinanceOpen,
    setIsCustomiseFinanceOpen,
  } = props;

  const { filters, setFilters, fields } = useFilters();

  const pricingCodes = usePricingCodes();

  const filterIcons = {
    [Filter_Names_Enum.Finance]: <FaCalculator className="inlineIcon" />,
    [Filter_Names_Enum.HasDiscount]: <AiOutlineStar className="inlineIcon" />,
    [Filter_Names_Enum.IsOnWishlist]: <AiOutlineHeart className="inlineIcon" />,
  };

  return (
    <div className={styles.filterBar}>
      <Container className={styles.container}>
        <SearchField isSearching={isSearching} />

        <div className={styles.mobileFilterToggle}>
          <Button
            onClick={() => setIsMobileFiltersOpen(!isMobileFiltersOpen)}
            color="white"
            size="small"
            aria-label="Filters"
          >
            <AiOutlineFilter />
          </Button>
        </div>

        <div className={styles.rightSideActions}>
          <Button.Group>
            {pricingCodes.options.length > 1 && (
              <Dropdown
                className={styles.pricingCodePicker}
                size="small"
                key={pricingCodes?.current}
                options={pricingCodes.options.map((option) => ({
                  label: option.short_name,
                  value: option.code,
                }))}
                defaultValue={pricingCodes?.current}
                onChange={pricingCodes.setCurrent}
                color={'white'}
              />
            )}

            {fields
              .filter((field) => field.location === Filter_Locations_Enum.TopRight)
              .map((field, i) => {
                switch (field.name) {
                  case Filter_Names_Enum.Finance:
                    return (
                      <Button
                        key={`${i}_${Math.random()}`/* avoid caching */}
                        className="hug"
                        color={'white'}
                        onClick={() => setIsCustomiseFinanceOpen(!isCustomiseFinanceOpen)}
                        size="small"
                      >
                        {filterIcons[field.name]}
                        {field.label}
                      </Button>
                    );
                  case Filter_Names_Enum.HasDiscount:
                  case Filter_Names_Enum.IsOnWishlist:
                    return (
                      <Button
                        key={`${i}_${Math.random()}`/* avoid caching */}
                        className="hug"
                        color={filters[field.name] ? 'black' : 'white'}
                        onClick={() => setFilters({ ...filters, [field.name]: !filters[field.name] })}
                        size="small"
                      >
                        {filterIcons[field.name]}
                        {field.label}
                      </Button>
                    );
                }
              })}
          </Button.Group>
        </div>
      </Container>
    </div>
  );
};
