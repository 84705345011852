import { gql } from '@apollo/client';

export const SEARCH_STOCKED_VEHICLES = gql`
  query VehiclesGetStockedVehicles(
    $search: String
    $where: stocked_vehicles_bool_exp
    $pricingCode: String
    $sort: [stocked_vehicles_order_by!]
  ) {
    search_stocked_vehicles(args: { search: $search }, where: $where, order_by: $sort) {
      id
      name
      code
      range
      pricing_config(path: $pricingCode)
      make
      model
      is_reserved_until
      badge
      badge_code
      media
      production_year
      odometer
      condition
      is_sold
      packs
      pack_codes
      useable_battery
      body_type
      estimated_delivery_delay
      available_from
      featured_order
      colour
      series
      fuel_type
    }
  }
`;
