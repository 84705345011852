/**
 * Forces a filter value to be an array
 *
 * Makes a string, number or boolean an array with a single respective element
 * Ensures that every element of an array is a string
 */
export const coherceArray = (value: (string | number)[] | boolean | Object) => {
  let arr = Array.isArray(value) ? value : [value];
  return arr.map((v) => String(v)).flat();
};
