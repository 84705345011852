import { useEffect, useRef, useState } from 'react';

/**
 * Moves known chat buttons out of the way
 *
 * Use this to allow for bottom-fixed banners to not cover up chat buttons
 *
 * @param isVisible Whether or not the banner is visible
 * @param offset The amount to offset the chat buttons by (in CSS units, e.g. pixels)
 */
export const useShiftChatButtons = (isVisible: boolean, offset: string) => {
  const [shouldShift, setShouldShift] = useState<boolean>(false);

  const shiftUp = () => {
    const chatElement = document.querySelector<HTMLButtonElement>('button#silc-btn');
    if (chatElement) {
      chatElement.style.transform = `translateY(-${offset})`;
    }
  };

  const shiftDown = () => {
    const chatElement = document.querySelector<HTMLButtonElement>('button#silc-btn');
    if (chatElement) {
      chatElement.style.transform = `translateY(0)`;
    }
  };

  // Re-evaluate every second
  // Some chat buttons are lazy-loaded, so we need to keep checking
  const intervalRef = useRef<NodeJS.Timeout>(null);
  useEffect(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    intervalRef.current = setInterval(() => {
      setShouldShift(isVisible);
    }, 1000);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }

      shiftDown();
    };
  }, [isVisible]);

  useEffect(() => {
    if (shouldShift) {
      shiftUp();
    } else {
      shiftDown();
    }
  }, [shouldShift]);
};
