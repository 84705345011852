import { Filter_Fields, Filter_Locations_Enum, Filter_Names_Enum } from '../../../../types/graphql';

export type FilterField = Pick<Filter_Fields, 'name' | 'label' | 'location' | 'options'>;

export const defaultFilterFields: FilterField[] = [
  {
    name: Filter_Names_Enum.Search,
    label: 'Search',
    location: Filter_Locations_Enum.TopLeft,
  },

  {
    name: Filter_Names_Enum.Finance,
    label: 'Finance',
    location: Filter_Locations_Enum.TopRight,
  },
  {
    name: Filter_Names_Enum.HasDiscount,
    label: 'Variant',
    location: Filter_Locations_Enum.TopRight,
  },
  {
    name: Filter_Names_Enum.IsOnWishlist,
    label: 'Saved',
    location: Filter_Locations_Enum.TopRight,
  },

  {
    name: Filter_Names_Enum.Sort,
    label: 'Sort by',
    location: Filter_Locations_Enum.LeftSidebar,
  },
  {
    name: Filter_Names_Enum.MaxRepayment,
    label: 'Budget',
    location: Filter_Locations_Enum.LeftSidebar,
  },
  {
    name: Filter_Names_Enum.Make,
    label: 'Brand',
    location: Filter_Locations_Enum.LeftSidebar,
  },
  {
    name: Filter_Names_Enum.Model,
    label: 'Model',
    location: Filter_Locations_Enum.LeftSidebar,
  },
  {
    name: Filter_Names_Enum.ProductionYear,
    label: 'Year',
    location: Filter_Locations_Enum.LeftSidebar,
  },
  {
    name: Filter_Names_Enum.Badge,
    label: 'Variant',
    location: Filter_Locations_Enum.LeftSidebar,
  },
  {
    name: Filter_Names_Enum.MaxPrice,
    label: 'Price',
    location: Filter_Locations_Enum.LeftSidebar,
    options: [
      { value: { max_price: { _lte: String(50_000_00) } }, label: '$50,000' },
      { value: { max_price: { _lte: String(100_000_00) } }, label: '$100,000' },
      { value: { max_price: { _lte: String(150_000_00) } }, label: '$150,000' },
    ].map((option) => ({ ...option, value: JSON.stringify(option.value) })),
  },
];
