import { gql } from '@apollo/client';

export const GET_FILTER_OPTIONS = gql`
  query GetFilterOptions($where: stocked_vehicles_bool_exp) {
    production_year: stocked_vehicles(distinct_on: production_year, order_by: { production_year: asc }, where: $where) {
      production_year
    }
    model: stocked_vehicles(distinct_on: model, order_by: { model: asc }, where: $where) {
      model
    }
    badge: stocked_vehicles(distinct_on: badge, order_by: { badge: asc }, where: $where) {
      badge
    }
    colour: stocked_vehicles(distinct_on: colour_code, where: $where) {
      colour
      colour_code
    }
    interior: stocked_vehicles(distinct_on: interior_code, where: $where) {
      interior
      interior_code
    }
    fuel_type: vehicle_specifications(distinct_on: fuel_type, where: { stocked_vehicles: $where }) {
      fuel_type
    }
  }
`;
