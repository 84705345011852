import { Order_By, Stocked_Vehicles_Order_By } from '../../../../../types/graphql';
import { Filters, SortOptions } from '../types';

type Args = {
  filters: Filters;
};

export const getSortClause = ({ filters }: Args): Array<Stocked_Vehicles_Order_By> => {
  const sort = filters.sort || SortOptions.MostViewed;

  switch (sort) {
    case SortOptions.PriceAscending:
      return [{ max_price: Order_By.Asc }];
    case SortOptions.PriceDescending:
      return [{ max_price: Order_By.Desc }];
    default:
      return [{ featured_order: Order_By.Asc }];
  }
};
