import { useFormContext } from 'react-hook-form';
import RadioList from '../../../../../../RadioList';
import { getSortOptions } from '../../../utils/getSortOptions';
import { FilterField } from '../../../defaultFilterFields';

type Props = {
  field: FilterField;
};

export const SortField = ({ field }: Props) => {
  const { register, setValue, watch } = useFormContext();

  const options = field.options || getSortOptions();

  return (
    <RadioList
      formFieldProps={{ className: 'hug' }}
      name={field.name}
      options={options}
      register={register}
      setValue={setValue}
      watch={watch}
      size={'small'}
    ></RadioList>
  );
};
