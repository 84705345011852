import classnames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { AiFillCaretDown, AiOutlineDown } from 'react-icons/ai';
import { FaAngleDown } from 'react-icons/fa';
import { useOnClickOutside } from '../../services/useOnClickOutside';
import styles from './index.module.scss';

export interface Props {
  className?: string;
  defaultValue: any;
  placeholder?: string;
  options: Array<{
    label: React.ReactNode;
    value: any;
  }>;
  onChange: (value: any) => any;
  size?: 'small' | 'medium' | 'large';
  color?: 'transparent' | 'white';
}

export default function Dropdown(props: Props) {
  const { className, options = [], defaultValue, onChange, size = 'medium', placeholder, color } = props;

  const [value, setValue] = useState(defaultValue);
  const label = options.find((option) => option.value === value)?.label || placeholder;

  useEffect(() => {
    onChange(value);
  }, [value]);

  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);
  useOnClickOutside(ref, () => {
    setIsOpen(false);
  });

  return (
    <div
      className={classnames(styles.dropdown, isOpen && styles.isOpen, styles[size], styles[color], className)}
      ref={ref}
    >
      <button type="button" className={styles.toggle} onClick={() => setIsOpen(!isOpen)}>
        {label}
        <AiFillCaretDown />
      </button>
      <div className={styles.options}>
        {options.map((option) => (
          <button
            key={option.value}
            type="button"
            onClick={() => {
              setValue(option.value);
              setIsOpen(false);
            }}
            className={styles.option}
          >
            {option.label}
          </button>
        ))}
      </div>
    </div>
  );
}
