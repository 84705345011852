import classnames from 'classnames';
import Image, { ImageProps } from 'next/image';
import React, { useEffect, useState } from 'react';
import Container from '../../../Container';
import styles from './index.module.scss';

export type Props = {
  className?: string;
  children?: React.ReactNode;
  aside?: React.ReactNode;
  background?: any;
  backgroundOpacity?: number;
  transparentBg?: boolean;
  color?: 'white' | 'black';
  backgroundLoader?: ImageProps['loader'];
} & Record<string, any>;

export default function CbaBanner(props: Props) {
  const {
    className,
    color,
    children,
    aside,
    background,
    backgroundLoader,
    backgroundOpacity = 1,
    fullWidth = false,
    transparentBg = false,
  } = props;

  const [isAsideVisible, setIsAsideVisible] = useState(false);
  useEffect(() => {
    setTimeout(() => setIsAsideVisible(true), 300);
  }, []);

  return (
    <header className={classnames(styles.banner, styles[color], className, transparentBg && styles.transparentBg)}>
      {background && (
        <div className={styles.background} style={{ opacity: backgroundOpacity }}>
          <Image src={background} layout="fill" loader={backgroundLoader} objectFit="cover" quality={80} priority />
        </div>
      )}
      <Container className={styles.container}>
        <div className={styles.content}>
          <div className={styles.inner}>{children}</div>
        </div>
      </Container>
    </header>
  );
}
