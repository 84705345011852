import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  AiOutlineArrowRight,
  AiOutlineCalculator,
  AiOutlineClose,
  AiOutlineCloseCircle,
  AiOutlineInfoCircle,
} from 'react-icons/ai';
import { FaCalculator } from 'react-icons/fa';
import { useCurrency } from '../../../../../../../services/formatCurrency';
import { Filter_Names_Enum } from '../../../../../../../types/graphql';
import Button from '../../../../../../Button';
import Slider from '../../../../../../Slider';
import { SortOptions } from '../../../types';
import { FieldProps } from '../types';

export const MaxRepaymentField = ({ field, financeState, onCustomiseFinance, onClose }: FieldProps) => {
  const { d } = useCurrency();

  const { register, setValue, watch } = useFormContext();

  const args = financeState?.args?.[financeState.settlement_method as keyof typeof financeState.args];
  const frequency = args?.repaymentFrequency || 'monthly';

  const min = frequency === 'monthly' ? 500_00 : 200_00;
  const max = frequency === 'monthly' ? 5000_00 : 1000_00;
  const step = frequency === 'monthly' ? 500_00 : 50_00;

  const value = watch(field.name);
  const interimName = `${field.name}_interim`;
  const interimValue = watch(interimName);

  useEffect(() => {
    let initialValue: number = value;

    if (!value) {
      initialValue = max;
    }
    if (value < min) {
      initialValue = min;
    }
    if (value > max) {
      initialValue = max;
    }

    setValue(interimName, initialValue);
  }, [financeState?.settlement_method]);

  const onReset = () => {
    setValue(field.name, null);
    setValue(interimName, max);
    setValue(Filter_Names_Enum.Sort, SortOptions.PriceAscending);
    onClose();
  };

  const onApply = () => {
    setValue(field.name, interimValue);
    setValue(Filter_Names_Enum.Sort, SortOptions.PriceDescending);
    onClose();
  };

  return (
    <div>
      <h3 className="hug">Budget setup</h3>
      <p>Less than:</p>
      <Slider
        formFieldProps={{ className: 'hug' }}
        name={interimName}
        register={register}
        setValue={setValue}
        watch={watch}
        size={'small'}
        min={min}
        max={max}
        step={step}
        helpText={
          <strong>
            {d(interimValue)} /{frequency === 'monthly' ? 'mo' : 'wk'}
          </strong>
        }
      ></Slider>

      <Button.Group justify="space-between">
        <Button.Group>
          <Button size="small" color="grey" onClick={() => onReset()}>
            <AiOutlineCloseCircle className="inlineIcon" />
            Reset
          </Button>
          <Button
            size="small"
            color="grey"
            onClick={() => {
              onCustomiseFinance();
              onClose();
            }}
          >
            <AiOutlineInfoCircle className="inlineIcon" />
            Customise repayments
          </Button>
        </Button.Group>
        <Button size="small" color="primary" onClick={() => onApply()}>
          Apply
          <AiOutlineArrowRight className="inlineIcon suffix" />
        </Button>
      </Button.Group>
    </div>
  );
};
