import { SettlementMethod } from '../../../../payment-calculators/types';
import { Filters } from '../types';

export enum FilterDataType {
  String = 'String',
  Number = 'Number',
  StringArray = 'StringArray',
  NumberArray = 'NumberArray',
  Object = 'Object',
  Boolean = 'Boolean',
}

export type SchemaItem =
  | {
      type: FilterDataType.String;
      default: string;
    }
  | {
      type: FilterDataType.StringArray;
      default: string[];
    }
  | {
      type: FilterDataType.Number;
      default: number;
    }
  | {
      type: FilterDataType.NumberArray;
      default: number[];
    }
  | {
      type: FilterDataType.Boolean;
      default: boolean;
    }
  | {
      type: FilterDataType.Object;
      default: Record<string, any>;
    };

export const filterSchema: Record<keyof Filters, SchemaItem> = {
  search: { type: FilterDataType.String, default: '' },
  production_year: { type: FilterDataType.StringArray, default: undefined },
  make: { type: FilterDataType.StringArray, default: undefined },
  model: { type: FilterDataType.StringArray, default: undefined },
  badge: { type: FilterDataType.StringArray, default: undefined },
  packs: { type: FilterDataType.StringArray, default: undefined },
  pack_codes: { type: FilterDataType.StringArray, default: undefined },
  body_type: { type: FilterDataType.StringArray, default: undefined },
  condition: { type: FilterDataType.StringArray, default: undefined },
  colour: { type: FilterDataType.StringArray, default: undefined },
  interior: { type: FilterDataType.StringArray, default: undefined },
  sort: { type: FilterDataType.String, default: undefined },
  max_price: { type: FilterDataType.Number, default: undefined },
  has_discount: { type: FilterDataType.Boolean, default: undefined },
  is_on_wishlist: { type: FilterDataType.Boolean, default: undefined },
  fuel_type: { type: FilterDataType.String, default: undefined },
  max_repayment: { type: FilterDataType.Number, default: undefined },
};
