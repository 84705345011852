import { imageKitLoader } from '../../../../services/getImagekitUrl';
import Banner from '../../../Banner';
import Markdown from '../../../Markdown';
import PageTitle from '../../../PageTitle';
import styles from './index.module.scss';

type Props = {
  title: string;
  description: string;
  headerImageUrl?: string;
  headerTextColor?: string;
  size?: 'small' | 'medium' | 'large';
};

export const VehicleListingPageHero = (props: Props) => {
  const { title, description, headerImageUrl, headerTextColor, size } = props;

  return headerImageUrl ? (
    <Banner size={size} textColor={headerTextColor} background={headerImageUrl} backgroundLoader={imageKitLoader}>
      <h1 className="h2">{title}</h1>
      <div style={{ maxWidth: '380px' }} className={'smallGap'}>
        <Markdown>{description}</Markdown>
      </div>
    </Banner>
  ) : (
    <div style={{ marginBottom: '1.5rem' }}>
      <PageTitle title={title} subTitle={description} />
    </div>
  );
};
