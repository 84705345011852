import { SettlementMethod } from '../../../../payment-calculators/types';
import { calculateFinance } from '../../../utils/calculateFinance';
import { FinanceDrawerState } from '../CustomiseFinanceDrawer';

/**
 * Takes a max repayment and determines the maximum vehicle that can be priced
 *
 * @returns the max price of a vehicle that can be financed with the given max repayment
 */
export const reverseLookupMaxRepayment = (maxRepayment: number, financeState: FinanceDrawerState): number => {
  const step = 100_00;
  let repayment = 0;
  let maxPrice = 0;

  const settlementMethod =
    financeState.settlement_method === SettlementMethod.Cash
      ? SettlementMethod.Finance_FixedRate
      : financeState.settlement_method;

  while (repayment < maxRepayment) {
    maxPrice += step;
    const finance = calculateFinance(
      maxPrice,
      {
        ...financeState,
        settlement_method: settlementMethod,
      },
      null,
      50_000_00,
    );
    repayment = finance?.repayment;
  }

  return maxPrice;
};
