import { useEffect, useState } from 'react';
import { AiOutlineArrowRight, AiOutlineCalendar } from 'react-icons/ai';
import { useShowroomSettings } from '../../../../services/showrooms/useShowroomSettings';
import Button from '../../../Button';
import Container from '../../../Container';
import styles from './index.module.scss';
import classnames from 'classnames';
import { useShiftChatButtons } from '../../../../services/useShiftChatButtons';
import { IoCloseOutline } from 'react-icons/io5';
import { AiOutlinePlus } from 'react-icons/ai';

export default function EnquireNowBanner() {
  const showroomSettings = useShowroomSettings(['feature_test_drives_enabled', 'enquiry_url']);

  const [isVisible, setIsVisible] = useState<boolean>(false);

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const toggleBannerExpansion = () => {
    const bannerExpansion = localStorage.getItem('enquireNowBannerExpansion');

    if (!bannerExpansion || bannerExpansion === 'closed') {
      setIsExpanded(true);
      localStorage.setItem('enquireNowBannerExpansion', 'open');
    } else {
      setIsExpanded(false);
      localStorage.setItem('enquireNowBannerExpansion', 'closed');
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;

      if (scrollPosition > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll, {
      passive: true,
    });

    const bannerExpansion = localStorage.getItem('enquireNowBannerExpansion');
    if (!bannerExpansion || bannerExpansion === 'open') {
      setIsExpanded(true);
    }

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useShiftChatButtons(isVisible, '80px');

  return (
    <>
      <div className={classnames(styles.enquireNowBanner, isVisible && isExpanded && styles.isVisible)}>
        <Container className={styles.container}>
          <div className={styles.content}>
            <div className={styles.title}>Ready to get started?</div>
          </div>
          <div className={styles.cta}>
            {showroomSettings.feature_test_drives_enabled && (
              <Button className={styles.testDriveButton} size="small" color="black" href={'/test-drive'}>
                <AiOutlineCalendar className="inlineIcon" />
                Book a test drive
              </Button>
            )}

            <Button
              className={'hug'}
              size="small"
              color="primary"
              href={showroomSettings?.enquiry_url || '/contact-us'}
            >
              Enquire now
              <AiOutlineArrowRight className="suffix inlineIcon" />
            </Button>
          </div>
        </Container>
        <Button isText className={classnames('hug', styles.closeButton)} onClick={toggleBannerExpansion}>
          <IoCloseOutline fontSize={30} />
        </Button>
      </div>
      <Button
        className={classnames('hug', styles.expandButton, !isExpanded && isVisible && styles.isVisible)}
        onClick={toggleBannerExpansion}
      >
        <AiOutlinePlus fontSize={30} />
      </Button>
    </>
  );
}
