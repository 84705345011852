import Link from 'next/link';
import styles from './index.module.scss';
import classnames from 'classnames';
import Container, { Props as ContainerProps } from '../Container';

export type Props = {
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  color?: 'black' | 'white';
  isCentred?: boolean;
  isFluid?: boolean;
  className?: string;
  cta?: React.ReactNode;
  bannerSrc?: string;
  icon?: React.ReactNode;
  containerSize?: ContainerProps['size'];
  islightSubTitle?: boolean;
};

export default function PageTitle(props: Props) {
  const {
    title,
    subTitle,
    color = 'white',
    isCentred = false,
    isFluid = false,
    className,
    cta,
    bannerSrc,
    icon,
    containerSize = 'normal',
    islightSubTitle = false,
  } = props;

  return (
    <header className={classnames(styles.pageTitle, styles[color], isCentred && styles.isCentred, className)}>
      {bannerSrc && <div className={styles.banner} style={{ backgroundImage: `url(${bannerSrc})` }} />}

      <Container isFluid={isFluid} className={styles.container} size={containerSize}>
        <div className={styles.content}>
          {icon && <div className={styles.icon}>{icon}</div>}
          <h1 className={classnames('hug', 'h2', styles.title)}>{title}</h1>
          {subTitle && <p className={islightSubTitle && styles.lightSubTitle}>{subTitle}</p>}
        </div>
        {cta && <div className={styles.cta}>{cta}</div>}
      </Container>
    </header>
  );
}
