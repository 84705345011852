import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { Filters } from './types';
import { useScrollToElement } from '../../../../services/useScrollToElement';
import { useHeaderHeight } from '../../../../services/useHeaderHeight';
import { getFiltersFromUrl } from './utils/getFiltersFromUrl';
import { useDebounce } from '../../../../services/useDebounce';
import { FilterField } from './defaultFilterFields';

type FilterContext = {
  filters: Partial<Filters>;
  setFilters: (filters: Partial<Filters>) => void;
  fields: FilterField[];
  resetFilters: () => void;
};

const Context = createContext<FilterContext>(null);

type Props = {
  children: ReactNode;
  fields: FilterField[];
  name: string;
};

export const FiltersProvider = (props: Props) => {
  const valueInLocalStorage = (() => {
    let value: Partial<Filters> = {};
    try {
      value = JSON.parse(window.localStorage.getItem(`filters_${props.name}`) || '{}');
    } catch (err) {
      // noop
    }
    return value;
  })();

  const filtersFromUrl = getFiltersFromUrl();
  const [filterSource, setFilterSource] = useState<'queryParams' | 'localStorage'>(
    Object.keys(filtersFromUrl)?.length > 0 ? 'queryParams' : 'localStorage',
  );

  const form = useForm<Partial<Filters>>({
    defaultValues: filterSource === 'queryParams' ? filtersFromUrl : valueInLocalStorage,
  });

  const filters = useWatch({ control: form.control }) as Partial<Filters>;
  const setFilters = (state: Partial<Filters>) => {
    form.reset({ ...state });
    window.localStorage.setItem(`filters_${props.name}`, JSON.stringify(state));
    setFilterSource('localStorage');
  };
  const [debouncedFilters] = useDebounce(filters, 500);

  useEffect(() => {
    window.localStorage.setItem(`filters_${props.name}`, JSON.stringify(filters));
    setFilterSource('localStorage');
  }, [filters]);

  useEffect(() => {
    setFilters(filterSource === 'queryParams' ? filtersFromUrl : valueInLocalStorage);
  }, [props.name]);

  const context: FilterContext = {
    filters,
    setFilters,
    fields: props.fields,
    resetFilters: () => setFilters({}),
  };

  const [hasInit, setHasInit] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setHasInit(true);
    }, 4000);
  }, []);

  const headerHeight = useHeaderHeight();
  const scrollToList = useScrollToElement('#list', headerHeight + 32);
  useEffect(() => {
    if (hasInit) {
      scrollToList();
    }
  }, [debouncedFilters]);

  return (
    <Context.Provider value={context}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(() => {})}>{props.children}</form>
      </FormProvider>
    </Context.Provider>
  );
};

export const useFilters = () => {
  const context = useContext<FilterContext>(Context);

  if (!context) {
    throw new Error('useFilters must be used within a FiltersProvider');
  }

  return context;
};
