import { useTranslation } from 'react-i18next';

export default function FixedRateCalculatorControlsDescription() {
  const { t } = useTranslation('Components:PaymentCalculators:FixedRateCalculator');

  return (
    <>
      {t(
        'description',
        'A simple way of financing that gives you the certainty of a fixed interest rate, and fixed monthly payments throughout the agreement.',
      )}
    </>
  );
}
