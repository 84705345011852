import {
  FixedRateFinance,
  FixedRateFinanceArgs,
  getFixedRateFinance,
} from '../../payment-calculators/products/FixedRate/calculate';
import {
  getGuaranteedFutureValueFinance,
  GuaranteedFutureValueFinance,
  GuaranteedFutureValueFinanceArgs,
} from '../../payment-calculators/products/GuaranteedFutureValue/calculate';
import { SettlementMethod } from '../../payment-calculators/types';
import { FinanceDrawerState } from '../components/Filters/CustomiseFinanceDrawer';
import { Vehicle } from '../types';

export type CalculatedFinance = FixedRateFinance | GuaranteedFutureValueFinance;

export const calculateFinance = (
  vehiclePrice: number,
  financeState: FinanceDrawerState,
  vehicle?: Vehicle,
  forceFutureValue?: number,
): CalculatedFinance => {
  const args = financeState?.args?.[financeState.settlement_method];

  if (!vehiclePrice || !args) {
    return null;
  }

  switch (financeState.settlement_method) {
    case SettlementMethod.Finance_FixedRate:
      return getFixedRateFinance({
        ...(args as FixedRateFinanceArgs),
        vehiclePrice,
      });
    case SettlementMethod.Finance_GuaranteedFutureValue:
      return getGuaranteedFutureValueFinance({
        ...(args as GuaranteedFutureValueFinanceArgs),
        vehiclePrice,
        vehicle,
        forceFutureValue,
      } as GuaranteedFutureValueFinanceArgs);
    default:
      return null;
  }
};
