import { useRouter } from 'next/router';
import { getImageCdnUrl } from '../../../../services/getImageCdnUrl';
import Button from '../../../Button';
import styles from './index.module.scss';

export type DropInAdType = {
  logo?: {
    src: string;
    alt?: string;
  };
  title: string;
  description: string;
  cta: {
    label: string;
    href: string;
  };
};

type Props = DropInAdType;

export const DropInAd = (props: Props) => {
  const { logo, title, description, cta } = props;

  const router = useRouter();

  return (
    <a
      className={styles.ad}
      href={cta.href}
      onClick={(e) => {
        e.preventDefault();
        router.push(cta.href);
      }}
    >
      <div className={styles.background} aria-hidden="true" />
      <div className={styles.content}>
        {logo && <img className={styles.logo} src={getImageCdnUrl(logo.src, { w: 180 })} alt={logo.alt} />}
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.description}>{description}</p>

        <Button color={'primary'}>{cta.label}</Button>
      </div>
    </a>
  );
};
